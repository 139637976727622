<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <van-field
                    v-model="phone.password"
                    type="password"
                    name="password"
                    label="登录密码"
                    placeholder="请输入登录密码"
                    :rules="[{ required: true, message: '请输入登录密码' }]"
                />
                <van-field
                    v-model="phone.phone"
                    type="phone"
                    name="phone"
                    label="新手机号"
                    placeholder="请输入新手机号"
                    :rules="[
                        { required: true, message: '请输入新手机号' },
                        { pattern, message: '手机号非法' }
                    ]"
                />
                <van-field
                    v-model="phone.phoneCode"
                    center
                    clearable
                    label="验证码"
                    placeholder="请输入手机验证码"
                >
                    <template #button>
                        <van-button
                            v-if="!sendPhoneTime"
                            size="small"
                            type="info"
                            @click="sendPhone()"
                        >获取验证码</van-button>
                        <van-button
                            v-else
                            size="small"
                            type="info"
                            disabled
                        >重发({{ sendPhoneTime }})</van-button>
                    </template>
                </van-field>
                <div class="sub_button">
                    <van-button round block type="primary" @click="submit">确 认</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { phone_erification_code_request,edit_phone_request } from '@/network/user'

export default {
    name:'UserPhoneComponent',
    data(){
        return {
            phone: {
                password: '',
                phone: '',
                phoneCode: '',
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改手机号',
            sendPhoneTime: 0,
            pattern: /^1[3,5,6,7,8,9]\d{9}$/
        }
    },
    computed:{},
    methods:{
        sendPhone() {
            this.$store.commit('true_loading')
            this.sendPhoneTime = 60
            phone_erification_code_request(this.phone)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('验证码已发送!')
                        const changeSendPhoneTime = setInterval(() => {
                            if (this.sendPhoneTime) {
                                this.sendPhoneTime--
                            } else {
                                clearInterval(changeSendPhoneTime)
                            }
                        }, 1000)
                    } else {
                        this.$toast.fail(s.msg)
                        this.sendPhoneTime = 0
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.sendPhoneTime = 0
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        submit() {
            this.$store.commit('true_loading')
            edit_phone_request(this.phone)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.$router.go(-1);//返回上一层
                    } else {
                        this.$toast.fail(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>